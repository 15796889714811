<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Delivery Orders')}}</h2>
      </div>
    </div>
    <table-filter :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    <div class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <export-button class="mr-4 float-left whitespace-nowrap"></export-button>
      <print-label-button class="mr-4 float-left whitespace-nowrap" :selected="selected"></print-label-button>
    </div>
    <shipblu-table
     :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @searchDate="handleSearchDate"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      searchDate
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Phone')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Delivered On')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: { type: 'delivery-orders', orderID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].createdOn">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone">
            {{ data[indextr].customer.phone }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].pickupDate">
            {{ data[indextr].pickup_date ? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : ''}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <template v-if="checkTooltip(data[indextr])">
              <vx-tooltip :text="data[indextr].failure_data.map(element => element.question === 'Choose reason for failed delivery'  ? new Date(element.created).toLocaleDateString('fr-CA') + '\n' + element.answer  : '').filter(element => element !== '').join('\n \n')">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </vx-tooltip>
            </template>
            <template v-else>
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
              </div>
            </template>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].deliveredOn">
            {{ data[indextr].delivered_date && data[indextr].status === 'delivered' ? new Date(data[indextr].delivered_date).toLocaleDateString('fr-CA') : '' }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import TableFilter from '../merchant/components/TableFilter.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['userRole'],
  data () {
    return {
      common,
      type: 'Add',
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: ''
        },
        {
          name: 'Created',
          value: 'created',
          color: 'bg-dark'
        },
        {
          name: 'Pickup Requested',
          value: 'pickup_requested',
          color: 'bg-warning'
        },
        {
          name: 'Picked Up',
          value: 'picked_up',
          color: 'bg-primary'
        },
        {
          name: 'In Transit',
          value: 'in_transit',
          color: 'bg-primary'
        },
        {
          name: 'Out For Delivery',
          value: 'out_for_delivery',
          color: 'bg-warning'
        },
        {
          name: 'Out For Return',
          value: 'out_for_return',
          color: 'bg-warning'
        },
        {
          name: 'Delivery Attempted',
          value: 'delivery_attempted',
          color: 'bg-danger'
        },
        {
          name: 'Delivered',
          value: 'delivered',
          color: 'bg-success'
        }
      ],
      filters: [],
      searchVal: '',
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      trackingData: {},
      trackingStatus: '',
      searchInProgress: false,
      searchedValue: ' ',
      fromDate: '',
      toDate: '',
      criteria: '',
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-delivery-orders-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadShipments()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadShipments()
      }
    },
    'selected' () {
      this.$emit('selected', this.selected)
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadShipments()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    checkTooltip (order) {
      return common.checkTooltip(order)
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.loadShipments()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadShipments()
    },
    loadShipments () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&${this.filters.includes('rto_requested') ? 'rto_requested=true' : `status=${this.filters}`}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/delivery-orders/${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-delivery-orders-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadShipments()
    },
    viewShipment (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-delivery-orders`,
        params: {
          type: 'delivery-orders',
          orderID: data.id
        }
      }).catch(() => {})
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    PrintLabelButton,
    TableFilter,
    ShipbluPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadShipments()
  }
}
export {common}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>